
import { getCurrTime } from "./utils";
import {
    GoldenSparklesCat,
    GoldenCat,
    OrangeCat,
    BlackCat,
    GoldenCatFacingLeft,
    OrangeCatFacingLeft,
    BlackCatFacingLeft,
    GoldenCatFacingRight,
    OrangeCatFacingRight,
    BlackCatFacingRight,
    GoldenSparklesCatStage1,
    GoldenSparklesCatStage2,
    YellowFlame,
    OrangeFlame,
    RedFlame1,
    RedFlame2,

    LeftBlackArm,
    RightBlackArm,
    LeftBlackArmExtended,
    RightBlackArmExtended,
    LeftBlackArmExtendedExtra,
    RightBlackArmExtendedExtra,
    RightBlackArmExtendedExtraExtra,

    LeftOrangeArm,
    RightOrangeArm,
    LeftOrangeArmExtended,
    RightOrangeArmExtended,
    LeftOrangeArmExtendedExtra,
    RightOrangeArmExtendedExtra,
    RightOrangeArmExtendedExtraExtra,

    LeftGoldenArm,
    RightGoldenArm,
    LeftGoldenArmExtended,
    RightGoldenArmExtended,
    LeftGoldenArmExtendedExtra,
    RightGoldenArmExtendedExtra,
    RightGoldenArmExtendedExtraExtra
} from "./ImportAssets.js";

export const characters = { 'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G', 'H': 'H', 'I': 'I', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M',
    'N': 'N', 'O': 'O', 'P': 'P', 'Q': 'Q', 'R': 'R', 'S': 'S', 'T': 'T', 'U': 'U', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z', '1': '1',
    '2': '2', '3': '3', '4': '4', '5': '5', '6': '6', '7': '7', '8': '8', '9': '9', '0': '0', '!': "1", '@': "2", '#': "3", '$': "4", '%': "5",
    '^': "6", '&': "7", '*': "8", '(': "9", ')': "0", '-': "Minus", '_': "Minus", '=': "Equal", '+': "Equal", '[': "OpenBracket",
    '{': "OpenBracket", ']': "CloseBracket", '}': "CloseBracket", '\\': "Backslash", '|': "Backslash", ';': "Semicolon", ':': "Semicolon", '\'': "Quotation",
    '"': "Quotation", ',': "Comma", '<': "Comma", '.': "Period", '>': "Period", '/': "Slash", '?': "Slash"
};

const character_position = { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 1, '7': 1, '8': 1, '9': 1, '0': 1, '!': 0, '@': 0, '#': 0, '$': 0, '%': 0, '^': 1, '&': 1,
    '*': 1, '(': 1, ')': 1, '-': 1, '_': 1, '=': 1, '+': 1, 'Q': 0, 'W': 0, 'E': 0, 'R': 0, 'T': 0, 'Y': 1, 'U': 1, 'I': 1, 'O': 1, 'P': 1, '[': 1, '{': 1,
    ']': 1, '}': 1, '\\': 1, '|': 1, 'A': 0, 'S': 0, 'D': 0, 'F': 0, 'G': 0, 'H': 1, 'J': 1, 'K': 1, 'L': 1, ';': 1, ':': 1, '\'': 1, '"': 1, 'Z': 0, 'X': 0,
    'C': 0, 'V': 0, 'B': 0, 'N': 1, 'M': 1, ',': 1, '<': 1, '.': 1, '>': 1, '/': 1, '?': 1
};

const arm_type = { '1': "LeftArmExtendedExtraImage", '2': "LeftArmExtendedExtraImage", '3': "LeftArmExtendedExtraImage", '4': "LeftArmExtendedExtraImage", '5': "LeftArmExtendedExtraImage",
    '6': "RightArmExtendedExtraExtraImage", '7': "RightArmExtendedExtraExtraImage", '8': "RightArmExtendedExtraExtraImage", '9': "RightArmExtendedExtraExtraImage", '0': "RightArmExtendedExtraExtraImage",
    '!': "LeftArmExtendedExtraImage", '@': "LeftArmExtendedExtraImage", '#': "LeftArmExtendedExtraImage", '$': "LeftArmExtendedExtraImage", '%': "LeftArmExtendedExtraImage",
    '^': "RightArmExtendedExtraExtraImage", '&': "RightArmExtendedExtraExtraImage", '*': "RightArmExtendedExtraExtraImage", '(': "RightArmExtendedExtraExtraImage",
    ')': "RightArmExtendedExtraExtraImage", '-': "RightArmExtendedExtraExtraImage", '_': "RightArmExtendedExtraExtraImage", '=': "RightArmExtendedExtraExtraImage",
    '+': "RightArmExtendedExtraExtraImage", 'Q': "LeftArmExtendedExtraImage", 'W': "LeftArmExtendedExtraImage", 'E': "LeftArmExtendedExtraImage", 'R': "LeftArmExtendedExtraImage",
    'T': "LeftArmExtendedExtraImage", 'Y': "RightArmExtendedExtraImage", 'U': "RightArmExtendedExtraImage", 'I': "RightArmExtendedExtraImage", 'O': "RightArmExtendedExtraImage",
    'P': "RightArmExtendedExtraImage", '[': "RightArmExtendedExtraImage", '{': "RightArmExtendedExtraImage", ']': "RightArmExtendedExtraImage", '}': "RightArmExtendedExtraImage",
    '\\': "RightArmExtendedExtraExtraImage", '|': "RightArmExtendedExtraImage", 'A': "LeftArmExtendedExtraImage", 'S': "LeftArmExtendedExtraImage", 'D': "LeftArmExtendedExtraImage",
    'F': "LeftArmExtendedExtraImage", 'G': "LeftArmExtendedImage", 'H': "RightArmExtendedExtraImage", 'J': "RightArmExtendedImage", 'K': "RightArmExtendedImage", 'L': "RightArmExtendedImage",
    ';': "RightArmExtendedImage", ':': "RightArmExtendedImage", '\'': "RightArmExtendedImage", '"': "RightArmExtendedImage", 'Z': "LeftArmExtendedImage", 'X': "LeftArmExtendedImage",
    'C': "LeftArmExtendedImage", 'V': "LeftArmExtendedImage", 'B': "LeftArmExtendedImage", 'N': "RightArmExtendedImage", 'M': "RightArmExtendedImage", ',': "RightArmExtendedImage",
    '<': "RightArmExtendedImage", '.': "RightArmExtendedImage", '>': "RightArmExtendedImage", '/': "RightArmExtendedImage", '?': "RightArmExtendedImage"
};

const catDirection = { '1': 0, '2': 0, '3': 0, '4': 0, '5': 1, '6': 1, '7': 1, '8': 1, '9': 2, '0': 2, '!': 0, '@': 0, '#': 0, '$': 0, '%': 1,
    '^': 1, '&': 1, '*': 1, '(': 2, ')': 2, '-': 2, '_': 2, '=': 2, '+': 2, 'Q': 0, 'W': 0, 'E': 0, 'R': 1, 'Y': 1, 'U': 1, 'I': 1, 'O': 2,
    'P': 2, '[': 2, '{': 2, ']': 2, '}': 2, '\\': 2, '|': 2, 'A': 0, 'S': 0, 'D': 0, 'F': 1, 'G': 1, 'H': 1, 'J': 1, 'K': 2, 'L': 2, ';': 2,
    ':': 2, '\'': 2, '"': 2, 'Z': 0, 'X': 0, 'C': 0, 'V': 1, 'B': 1, 'N': 1, 'M': 2, ',': 2, '<': 2, '.': 2, '>': 2, '/': 2, '?': 2
};

export function getCatBodyForKey(state, key) {
    const direction = catDirection[key];
    if (direction === 0) {
      return getCatFacingLeftBody(state);
    } else if (direction === 2) { // TODO: combine left and right functions
      return getCatFacingRightBody(state);
    } else {
      return getCatDefaultBody(state);
    }
};

function getCatFacingLeftBody(state) {
    if (state.launchedSuperSaiyanCat) {
      return state.theGoldenCat
    } else if (state.wpm <= 30 || getCurrTime() <= state.startTime + 3) {
      return BlackCatFacingLeft
    } else if (state.wpm <= 60) {
      return OrangeCatFacingLeft
    } else if (state.wpm <= 100) {
      return GoldenCatFacingLeft
    } else {
      return GoldenSparklesCat
    }
}

function getCatFacingRightBody(state) {
    if (state.launchedSuperSaiyanCat) {
      return state.theGoldenCat
    } else if (state.wpm <= 30 || getCurrTime() <= state.startTime + 3) {
      return BlackCatFacingRight
    } else if (state.wpm <= 60) {
      return OrangeCatFacingRight
    } else if (state.wpm <= 100) {
      return GoldenCatFacingRight
    } else {
      return GoldenSparklesCat
    }
}

export function getCatDefaultBody(state) {
    if (state.launchedSuperSaiyanCat) {
      return state.theGoldenCat
    } else if (state.wpm <= 30 || getCurrTime() <= state.startTime + 3) {
      return BlackCat
    } else if (state.wpm <= 60) {
      return OrangeCat
    } else if (state.wpm <= 100) {
      return GoldenCat
    } else {
      return state.theGoldenCat
    }
}

export function updateCatFrameToDefault(app) {
    var LeftArmImage = null;
    var RightArmImage = null;

    if (app.state.launchedSuperSaiyanCat) {
      LeftArmImage = LeftGoldenArm
      RightArmImage = RightGoldenArm
    } else if (app.state.wpm <= 30 || getCurrTime() <= app.state.startTime + 3) {
      LeftArmImage = LeftBlackArm
      RightArmImage = RightBlackArm
     } else if (app.state.wpm <= 60) {
      LeftArmImage = LeftOrangeArm
      RightArmImage = RightOrangeArm
     } else {
      LeftArmImage = LeftGoldenArm
      RightArmImage = RightGoldenArm
    }

    var catBodyImage = getCatDefaultBody(app.state);
    app.setState({ catFrameBody: catBodyImage,
                    catFrameLeftArm: LeftArmImage,
                    catFrameRightArm: RightArmImage,
                    catStyleBody: "CatFrame",
                    catStyleLeftArm: "CatArmFrameLeft",
                    catStyleRightArm: "CatArmFrameRight",
                  });
}

export function updateCatTypingFrame(app, key) {
    var LeftArmImage = null;
    var RightArmImage = null;
    var LeftArmExtendedImage = null;
    var RightArmExtendedImage = null;
    var LeftArmExtendedExtraImage = null;
    var RightArmExtendedExtraImage = null;
    var RightArmExtendedExtraExtraImage = null;

    if (app.state.launchedSuperSaiyanCat) {
      LeftArmImage = LeftGoldenArm
      RightArmImage = RightGoldenArm
      LeftArmExtendedImage = LeftGoldenArmExtended
      RightArmExtendedImage = RightGoldenArmExtended
      LeftArmExtendedExtraImage = LeftGoldenArmExtendedExtra
      RightArmExtendedExtraImage = RightGoldenArmExtendedExtra
      RightArmExtendedExtraExtraImage = RightGoldenArmExtendedExtraExtra
    } else if (app.state.wpm <= 30 || getCurrTime() <= app.state.startTime + 3) {
      LeftArmImage = LeftBlackArm
      RightArmImage = RightBlackArm
      LeftArmExtendedImage = LeftBlackArmExtended
      RightArmExtendedImage = RightBlackArmExtended
      LeftArmExtendedExtraImage = LeftBlackArmExtendedExtra
      RightArmExtendedExtraImage = RightBlackArmExtendedExtra
      RightArmExtendedExtraExtraImage = RightBlackArmExtendedExtraExtra
    } else if (app.state.wpm <= 60) {
      LeftArmImage = LeftOrangeArm
      RightArmImage = RightOrangeArm
      LeftArmExtendedImage = LeftOrangeArmExtended
      RightArmExtendedImage = RightOrangeArmExtended
      LeftArmExtendedExtraImage = LeftOrangeArmExtendedExtra
      RightArmExtendedExtraImage = RightOrangeArmExtendedExtra
      RightArmExtendedExtraExtraImage = RightOrangeArmExtendedExtraExtra
    } else {
      LeftArmImage = LeftGoldenArm
      RightArmImage = RightGoldenArm
      LeftArmExtendedImage = LeftGoldenArmExtended
      RightArmExtendedImage = RightGoldenArmExtended
      LeftArmExtendedExtraImage = LeftGoldenArmExtendedExtra
      RightArmExtendedExtraImage = RightGoldenArmExtendedExtra
      RightArmExtendedExtraExtraImage = RightGoldenArmExtendedExtraExtra
    }

    var symbol = key;

    if (symbol in characters) {
      symbol = characters[symbol];

      var position = character_position[key];
      var left;
      var right;
      var leftType;
      var rightType;

      if (position === 0) {
        left = symbol;
        right = "Right";
        leftType = arm_type[key];
        rightType = "RightArmImage";
      } else {
        left = "Left";
        right = symbol;
        leftType = "LeftArmImage";
        rightType = arm_type[key];
      }
    } else {
      symbol = "";
      left = "Left";
      right = "Right";
      leftType = "LeftArmImage";
      rightType = "RightArmImage";
    }

    var catBodyImage = getCatBodyForKey(app.state, app.state.currentKey)
    app.setState({ catFrameBody: catBodyImage,
                    catFrameLeftArm: eval(leftType),
                    catFrameRightArm: eval(rightType),
                    catStyleBody: "CatFrame",
                    catStyleLeftArm: "CatArmFrame" + left,
                    catStyleRightArm: "CatArmFrame" + right,
                  });
};

export function animateFlamesIfNeeded(app) {
    if (app.state.launchedSuperSaiyanCat) {
      return;
    }

    app.setState({ launchedSuperSaiyanCat: true })
    app.purrFX.pause();
    app.meowFX.play(); //play cat meowing first!

    //then play the explosion sound effect later, after 700ms, and animate the explosion frames
    setTimeout(() => {
      app.explosionFX.play();

      var flameFrame1 = YellowFlame;
      var flameFrame2 = OrangeFlame;
      var flameFrame3 = RedFlame1;
      var flameFrame4 = RedFlame2;

      var holdingDelay = 250;
      var flameFrames = [flameFrame1, flameFrame2, flameFrame3, flameFrame4, flameFrame3, flameFrame4, flameFrame3, flameFrame4];
      var goldenCatFrames = [GoldenSparklesCatStage1, GoldenSparklesCatStage2, GoldenSparklesCat]

      //this part animates the flame frames from the explosion
      for (let i = 0; i < flameFrames.length; i++) {
        setTimeout(() => {
          var theFlameFrame = flameFrames[i]
          app.setState({currentFlameFrame: theFlameFrame})
        }, holdingDelay * i);
      }

      //this part animates the super saiyan cat's hair
      for (let i = 0; i < goldenCatFrames.length; i++) {
        setTimeout(() => {
          var goldenCatFrame = goldenCatFrames[i]
          app.setState({theGoldenCat: goldenCatFrame,
                         catFrameBody: goldenCatFrame,
                        })
        }, holdingDelay * i);
      }

      //we stop showing the flame animation frames after they all appeared
      setTimeout(() => {
        app.setState({ currentFlameFrame: "",
                      })
      }, holdingDelay * flameFrames.length);

    }, 700);
};

export function playTypingSoundEffect(app) {
  if (app.state.typingFXOn) {
    app.typingFX.currentTime = 0;
    app.typingFX.play();
  }
};

export function playLaserSoundEffect(app) {
    if (app.state.laserFXOn) {
      app.laserFX.currentTime = 0;
      app.laserFX.play();
    }
};

export function setTypingSoundFX(app) {
  if (app.state.typingFXOn) {
    app.setState({ typingFXOn: false })
  } else {
    app.setState({ typingFXOn: true })
  }
};

export function setLaserSoundFX(app) {
  if (app.state.laserFXOn) {
    app.setState({ laserFXOn: false })
  } else {
    app.setState({ laserFXOn: true })
  }
};

export function playBackgroundMusic(app) {
  setTimeout(() => {
    if (app.state.musicOn) {
      app.backgroundMusic.play();
      app.purrFX.play();
    } else {
      app.backgroundMusic.pause();
      app.backgroundMusic.currentTime = 0;
      app.purrFX.pause();
      app.purrFX.currentTime = 0;
    }
  }, 250);
};

export function toggleBackgroundMusic(app) {
  if (app.state.musicOn) {
    app.setState({ musicOn: false }, () => {
      playBackgroundMusic(app);
    });
  } else {
    app.setState({ musicOn: true }, () => {
      playBackgroundMusic(app);
    })
  }
}
