export { default as Keyboard } from './assets/Other/keyboard.png';
export { default as GoldenSparklesCat } from './assets/CatPictures/GoldenSparklesCatFacingCenter.png';
export { default as GoldenCat } from './assets/CatPictures/GoldenCatFacingCenter.png';
export { default as OrangeCat } from './assets/CatPictures/OrangeCatFacingCenter.png';
export { default as BlackCat } from './assets/CatPictures/BlackCatFacingCenter.png';
export { default as GoldenCatFacingLeft } from './assets/CatPictures/GoldenCatFacingLeft.png';
export { default as OrangeCatFacingLeft } from './assets/CatPictures/OrangeCatFacingLeft.png';
export { default as BlackCatFacingLeft } from './assets/CatPictures/BlackCatFacingLeft.png';
export { default as GoldenCatFacingRight } from './assets/CatPictures/GoldenCatFacingRight.png';
export { default as OrangeCatFacingRight } from './assets/CatPictures/OrangeCatFacingRight.png';
export { default as BlackCatFacingRight } from './assets/CatPictures/BlackCatFacingRight.png';
export { default as GoldenSparklesCatStage1 } from './assets/CatPictures/GoldenSparklesCatStage1.png';
export { default as GoldenSparklesCatStage2 } from './assets/CatPictures/GoldenSparklesCatStage2.png';
export { default as YellowFlame } from './assets/Fire/yellow.png';
export { default as OrangeFlame } from './assets/Fire/orange.png';
export { default as RedFlame1 } from './assets/Fire/red1.png';
export { default as RedFlame2 } from './assets/Fire/red2.png';

export { default as LeftBlackArm } from './assets/LeftArms/Regular/LeftBlackArm.png';
export { default as RightBlackArm } from './assets/RightArms/Regular/RightBlackArm.png';
export { default as LeftBlackArmExtended } from './assets/LeftArms/Extended/LeftBlackArmExtended.png';
export { default as RightBlackArmExtended } from './assets/RightArms/Extended/RightBlackArmExtended.png';
export { default as LeftBlackArmExtendedExtra } from './assets/LeftArms/ExtendedExtra/LeftBlackArmExtendedExtra.png';
export { default as RightBlackArmExtendedExtra } from './assets/RightArms/ExtendedExtra/RightBlackArmExtendedExtra.png';
export { default as RightBlackArmExtendedExtraExtra } from './assets/RightArms/ExtendedExtraExtra/RightBlackArmExtendedExtraExtra.png';

export { default as LeftOrangeArm } from './assets/LeftArms/Regular/LeftOrangeArm.png';
export { default as RightOrangeArm } from './assets/RightArms/Regular/RightOrangeArm.png';
export { default as LeftOrangeArmExtended } from './assets/LeftArms/Extended/LeftOrangeArmExtended.png';
export { default as RightOrangeArmExtended } from './assets/RightArms/Extended/RightOrangeArmExtended.png';
export { default as LeftOrangeArmExtendedExtra } from './assets/LeftArms/ExtendedExtra/LeftOrangeArmExtendedExtra.png';
export { default as RightOrangeArmExtendedExtra } from './assets/RightArms/ExtendedExtra/RightOrangeArmExtendedExtra.png';
export { default as RightOrangeArmExtendedExtraExtra } from './assets/RightArms/ExtendedExtraExtra/RightOrangeArmExtendedExtraExtra.png';

export { default as LeftGoldenArm } from './assets/LeftArms/Regular/LeftGoldenArm.png';
export { default as RightGoldenArm } from './assets/RightArms/Regular/RightGoldenArm.png';
export { default as LeftGoldenArmExtended } from './assets/LeftArms/Extended/LeftGoldenArmExtended.png';
export { default as RightGoldenArmExtended } from './assets/RightArms/Extended/RightGoldenArmExtended.png';
export { default as LeftGoldenArmExtendedExtra } from './assets/LeftArms/ExtendedExtra/LeftGoldenArmExtendedExtra.png';
export { default as RightGoldenArmExtendedExtra } from './assets/RightArms/ExtendedExtra/RightGoldenArmExtendedExtra.png';
export { default as RightGoldenArmExtendedExtraExtra } from './assets/RightArms/ExtendedExtraExtra/RightGoldenArmExtendedExtraExtra.png';

export { default as LaserPointer } from './assets/Other/LaserPointer.png';
export { default as LaserLine } from './assets/Other/LaserLine.png';
export { default as LaserDot } from './assets/Other/LaserDot.png';

export { default as typing } from './assets/SoundFX/typing.mp3';
export { default as laser } from './assets/SoundFX/laser.mp3';
export { default as music } from './assets/Music/music.mp3';
export { default as meow } from './assets/SoundFX/meow.mp3';
export { default as purr } from './assets/SoundFX/purr.mp3';
export { default as explosion } from './assets/SoundFX/explosion.mp3';