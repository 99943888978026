import React, { Component } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Button from "react-bootstrap/Button.js";
import Cookies from "js-cookie";
import FastestWordsDisplay from "./FastestWordsDisplay";
import Input from "./Input.js";
import Indicators from "./Indicators.js";
import Leaderboard from "./Leaderboard.js";
import LoadingDialog from "./LoadingDialog.js";
import Options from "./Options.js";
import OpeningDialog from "./OpeningDialog.js";
import post from "./post";
import Prompt from "./Prompt.js";
import ProgressBars from "./ProgressBars.js";
import CatProgressBar from "./CatProgressBar.js";
import HighScorePrompt from "./HighScorePrompt.js";
import TopicPicker from "./TopicPicker";
import { getCurrTime, randomString } from "./utils";

import {
  characters,
  updateCatFrameToDefault,
  updateCatTypingFrame,
  animateFlamesIfNeeded,
  playTypingSoundEffect,
  playLaserSoundEffect,
  setTypingSoundFX,
  setLaserSoundFX,
  playBackgroundMusic,
  toggleBackgroundMusic
} from "./CatAnimationHelper.js";

import {
  Keyboard,
  BlackCat,
  GoldenSparklesCatStage1,
  LeftBlackArm,
  RightBlackArm,
  LaserPointer,
  LaserLine,
  LaserDot,
  typing,
  laser,
  music,
  meow,
  purr,
  explosion
} from "./ImportAssets.js";

export const Mode = {
  SINGLE: "single",
  MULTI: "multi",
  WELCOME: "welcome",
  WAITING: "waiting",
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promptedWords: ["Please wait - loading!"],
      currentKey: " ",
      isAnimating: false,
      typedWords: [],
      wpm: null,
      accuracy: null,
      startTime: 0,
      currTime: 0,
      pigLatin: false,
      autoCorrect: false,
      currWord: "",
      inputActive: false,
      numPlayers: 1,
      mode: Mode.SINGLE,
      playerList: [],
      progress: [],
      showLeaderboard: false,
      fastestWords: [],
      showUsernameEntry: false,
      needVerify: false,
      topics: [],
      musicOn: false,
      typingFXOn: true,
      laserFXOn: true,
      launchedSuperSaiyanCat: false,
      currentFlameFrame: "",
      theGoldenCat: GoldenSparklesCatStage1,
      hasPlayedPurringSound: false,
      catFrameBody: BlackCat,
      catFrameLeftArm: LeftBlackArm,
      catFrameRightArm: RightBlackArm,
      catStyleBody: "CatFrame",
      catStyleLeftArm: "CatArmFrameLeft",
      catStyleRightArm: "CatArmFrameRight",
      laserFramePointer: LaserPointer,
      laserFrameDot: "",
      laserFrameLine: "",
      laserStylePointer: "LaserFrame",
      laserStyleDot: "LaserDot",
      laserStyleLine: "LaserLine",
    };

    this.timer = null;
    this.multiplayerTimer = null;
    this.saiyanCatArmReturnTimer = null;

    post("/request_id").then((id) => {
      if (id !== null) {
        this.setState({ id: id.toString(), mode: Mode.WELCOME });
      }
    });

    if (!Cookies.get("user")) {
      Cookies.set("user", randomString(32));
    }
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate() {
    if (
      this.state.mode === Mode.WELCOME ||
      this.state.mode === Mode.WAITING ||
      this.state.showLeaderboard
    ) {
      document.getElementById("app-root").style.filter = "blur(5px)";
    } else {
      document.getElementById("app-root").style.filter = "none";
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.multiplayerTimer);
  }

  initialize = () => {

    if (this.backgroundMusic) {
      this.backgroundMusic.pause();
    }

    if (this.purrFX) {
      this.purrFX.pause();
    }

    this.setState({
      typedWords: [],
      currWord: "",
      inputActive: true,
      wpm: null,
      accuracy: null,
      fastestWords: [],
    });

    post("/request_paragraph", { topics: this.state.topics }).then((data) => {
      if (this.state.pigLatin) {
        post("/translate_to_pig_latin", {
          text: data,
        }).then((translated) => {
          this.setState({
            promptedWords: translated.split(" "),
          });
        });
      } else {
        this.setState({
          promptedWords: data.split(" "),
        });
      }
    });

    this.setState({ startTime: 0, currTime: 0 });

    clearInterval(this.timer);
    this.timer = null;

    this.backgroundMusic = new Audio(music);
    this.backgroundMusic.volume = 0.2;
    this.backgroundMusic.loop = true;

    this.purrFX = new Audio(purr);
    this.purrFX.volume = 0;
    this.purrFX.loop = true;

    this.typingFX = new Audio(typing);
    this.typingFX.volume = 0.8;

    this.laserFX = new Audio(laser);
    this.laserFX.volume = 0.05;

    this.meowFX = new Audio(meow);

    this.explosionFX = new Audio(explosion);
    this.explosionFX.volume = 0.3;

    playBackgroundMusic(this);
  };

  restart = () => {
    this.timer = setInterval(this.updateReadouts, 100);
    this.setState({
      startTime: getCurrTime(),
      currTime: getCurrTime(),
    });
  };

  updateReadouts = async () => {
    const promptedText = this.state.promptedWords.join(" ");
    const typedText = this.state.typedWords.join(" ");
    const { wpm, accuracy } = await post("/analyze", {
      promptedText,
      typedText,
      startTime: this.state.startTime,
      endTime: getCurrTime(),
    });
    this.setState({ wpm, accuracy, currTime: getCurrTime() });
    return { wpm, accuracy };
  };

  reportProgress = () => {
    const promptedText = this.state.promptedWords.join(" ");
    post("/report_progress", {
      id: this.state.id,
      typed: this.state.typedWords.join(" "),
      prompt: promptedText,
    });
  };

  requestProgress = async () => {
    const progress = await post("/request_progress", {
      targets: this.state.playerList,
    });
    this.setState({
      progress,
    });
    if (progress.every(([x]) => x === 1.0)) {
      clearInterval(this.multiplayerTimer);
      this.fastestWords();
    }
  };

  fastestWords = async () => {
    const fastestWords = await post("/fastest_words", {
      targets: this.state.playerList,
      prompt: this.state.promptedWords.join(" "),
    });
    this.setState({ fastestWords });
  };

  popPrevWord = () => {
    if (this.state.typedWords.length !== 0) {
      const out = this.state.typedWords[this.state.typedWords.length - 1];
      this.setState((state) => ({
        typedWords: state.typedWords.slice(0, state.typedWords.length - 1),
      }));
      return out;
    } else {
      return "";
    }
  };

  handleWordTyped = (word) => {
    if (!word) {
      return true;
    }

    const wordIndex = this.state.typedWords.length;

    const afterWordTyped = () => {
      this.updateReadouts();
      if (this.state.mode === Mode.MULTI) {
        this.reportProgress();
      }
    };

    this.setState((state) => {
      if (state.autoCorrect && word !== state.promptedWords[wordIndex]) {
        post("/autocorrect", { word }).then((data) => {
          // eslint-disable-next-line no-shadow
          this.setState((state) => {
            if (state.typedWords[wordIndex] !== word) {
              return {};
            }
            const { typedWords } = state;
            typedWords[wordIndex] = data;
            return { typedWords };
          });
        });
      }
      return {
        typedWords: state.typedWords.concat([word]),
        currWord: "",
      };
    }, afterWordTyped);

    return true;
  };

  handleChange = async (currWord) => {
    this.setState({ currWord });
    if (
      this.state.typedWords.length + 1 === this.state.promptedWords.length &&
      this.state.promptedWords[this.state.promptedWords.length - 1] ===
        currWord &&
      (this.state.mode === Mode.SINGLE ||
        this.state.typedWords.concat([currWord]).join(" ") ===
          this.state.promptedWords.join(" "))
    ) {
      clearInterval(this.timer);
      this.setState({ inputActive: false });
      this.handleWordTyped(currWord);
      const token = Cookies.get("token") || null;
      const { eligible, needVerify } = await post(
        "/check_leaderboard_eligibility",
        {
          user: Cookies.get("user"),
          wpm: this.state.wpm,
          token,
        }
      );
      const { wpm } = await this.updateReadouts();
      this.setState({ wpm }, () => {
        if (eligible && this.state.accuracy === 100) {
          this.setState({ showUsernameEntry: true, needVerify });
        }
      });
    } else if (!this.timer) {
      this.restart();
    }
  };

  handlePigLatinToggle = () => {
    this.initialize();
    this.setState((state) => ({
      autoCorrect: false,
      pigLatin: !state.pigLatin,
    }));
  };

  handleAutoCorrectToggle = () => {
    this.initialize();
    this.setState((state) => ({
      autoCorrect: !state.autoCorrect,
      pigLatin: false,
    }));
  };

  setMode = (mode) => {
    this.setState({ mode });
    if (mode === Mode.WAITING) {
      this.multiplayerTimer = setInterval(this.requestMatch, 1000);
    }
  };

  requestMatch = async () => {
    const data = await post("/request_match", { id: this.state.id });
    if (data.start) {
      this.setState({
        mode: Mode.MULTI,
        playerList: data.players,
        numPlayers: data.players.length,
        promptedWords: data.text.split(" "),
        progress: new Array(data.players.length).fill([0, 0]),
        pigLatin: false,
        autoCorrect: false,
      });
      clearInterval(this.multiplayerTimer);
      this.multiplayerTimer = setInterval(this.requestProgress, 500);
    } else {
      this.setState({
        numPlayers: data.numWaiting,
      });
    }
  };

  toggleLeaderBoard = () => {
    this.setState(({ showLeaderboard }) => ({
      showLeaderboard: !showLeaderboard,
    }));
  };

  handleSetTopics = (topics) => {
    this.setState({ topics }, this.initialize);
  };

  handleUsernameSubmission = async (name) => {
    await post("/record_wpm", {
      name,
      user: Cookies.get("user"),
      wpm: this.state.wpm,
      token: Cookies.get("token") || null,
    });
    this.hideUsernameEntry();
  };

  hideUsernameEntry = () => {
    this.setState({ showUsernameEntry: false });
  };

  updateLaserFrameToDefault() {
    this.setState({ laserFramePointer: LaserPointer,
                    laserFrameLine: "",
                    laserFrameDot: "",
                    laserStylePointer: "LaserFrame",
                    laserStyleLine: "LaserLine",
                    laserStyleDot: "LaserDot",
                  })
  };

  updateLaserTypingFrame(key) {
    var symbol = key;

    if (symbol in characters) {
      symbol = characters[symbol];
    } else {
      symbol = "";
    }

    this.setState({ laserFramePointer: LaserPointer,
                    laserFrameLine: LaserLine,
                    laserFrameDot: LaserDot,
                    laserStylePointer: "LaserFrame" + symbol,
                    laserStyleLine: "LaserLine" + symbol,
                    laserStyleDot: "LaserDot" + symbol,
                  })
  };

  animateCat(currentWPM) { // not refactored
    if (this.state.isAnimating) {
      return
    }
    this.setState({ isAnimating: true });
    var typingDelay = 0;
    var holdingDelay = 0;

    if (currentWPM <= 30 || currentWPM === 0 || getCurrTime() <= this.state.startTime + 3) { // Black Cat...
      typingDelay = 60;
      holdingDelay = 220;
    } else if (currentWPM <= 60) { // Orange Tabby.
      typingDelay = 40;
      holdingDelay = 190;
    } else { // Golden Cat! Or when the user did not type anything yet...
      typingDelay = 30;
      holdingDelay = 170;
    }

    // Switch to the typing frame after typingDelay milliseconds
    setTimeout(() => {
      updateCatTypingFrame(this, this.state.currentKey);
      playTypingSoundEffect(this) // play sound effect!
    }, typingDelay);

    // Switch back to the default laser frame after laserDelay milliseconds
    setTimeout(() => {
      this.setState({ currentKey: " "});
      updateCatFrameToDefault(this);
    }, typingDelay + holdingDelay);

    // Turn off animation blocker flag after laserDelay milliseconds to allow future animations to occur
    setTimeout(() => {
      this.setState({ isAnimating: false });
    }, typingDelay + holdingDelay + 50);
  }

  handleKeyDown = (event) => {

    const pressedKey = event.key.toUpperCase(); // Capture the key and convert to uppercase
    if (!this.state.currentKey || !this.state.inputActive || (!(pressedKey in characters))) { // if the prompt is done, cat will not type
      this.updateLaserFrameToDefault();
      updateCatFrameToDefault(this);
      return;
    }

    this.setState({ currentKey: pressedKey})
    const currentWPM = this.state.wpm; // don't want to get total WPM, but current WPM

    // Cat follows the laser instantly
    if ((currentWPM > 100 && getCurrTime() > this.state.startTime + 3) || this.state.launchedSuperSaiyanCat) {
      animateFlamesIfNeeded(this);
      updateCatTypingFrame(this, pressedKey);
      this.updateLaserTypingFrame(pressedKey);
      playLaserSoundEffect(this); // play sound effect!
      playTypingSoundEffect(this) // play sound effect!
      setTimeout(() => {
        this.updateLaserFrameToDefault();
      }, 200);


      // clear the old timer so that it won't trigger, then create a new timeout to extend the timeout by another interval
      clearTimeout(this.saiyanCatArmReturnTimer);
      this.saiyanCatArmReturnTimer = setTimeout(() => {
        updateCatFrameToDefault(this);
      }, 300)
    } else { // Laser types and then cat follows after a delay

      if (!this.state.launchedSuperSaiyanCat) {
        var volumeLevel = Math.min(1.0, currentWPM / 100.0);
        this.purrFX.volume = volumeLevel;
      }
      this.updateLaserTypingFrame(pressedKey);

      setTimeout(() => {
        this.updateLaserFrameToDefault();
        playLaserSoundEffect(this);
      }, 200);

      this.animateCat(currentWPM);
    }
  };

  render() {
    const {
      wpm,
      accuracy,
      numPlayers,
      startTime,
      currTime,
      playerList,
      id,
      fastestWords,
    } = this.state;
    const remainingTime = (currTime - startTime).toFixed(1);
    const playerIndex = playerList.indexOf(id);

    return (
      <>

        {/* Main components */}
        <div className="App container" id="app-root">
          <div className="row">
            <div className="col">
              <br />

              {/* Side display */}
              <div className="side-display">
                {/* Buttons for GUI settins */}
                <div className="settings-buttons">
                  <Button
                    onClick={() => this.toggleLeaderBoard(false)}
                    variant="outline-dark"
                  >
                    Leaderboard
                  </Button>
                  <Button
                    variant="outline-dark"
                    onClick={() => toggleBackgroundMusic(this)}
                  >
                    {this.state.musicOn ? "Background Music is On" : "Background Music is Off"}
                  </Button>
                  <Button
                    variant="outline-dark"
                    onClick={() => setTypingSoundFX(this)}
                  >
                    {this.state.typingFXOn ? "Typing Sound FX is On" : "Typing Sound FX is Off"}
                  </Button>
                  <Button
                    variant="outline-dark"
                    onClick={() => setLaserSoundFX(this)}
                  >
                    {this.state.laserFXOn ? "Laser Sound FX is On" : "Laser Sound FX is Off"}
                  </Button>
                </div>
                {/* Progress bar */}
                <CatProgressBar
                  progress={this.state.wpm}
                />
                {/* Statistics */}
                <div className="typing-stats">
                  <Indicators
                    wpm={wpm}
                    accuracy={accuracy}
                    remainingTime={remainingTime}
                  />
                </div>
              </div>

              {/* GUI title */}
              <h1 className="display-4mainTitle">
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <b>C</b>omputer <b>A</b>ided <b>T</b>yping <b>S</b>oftware
              </h1>

              {/* Progress bar */}
              {this.state.mode === Mode.MULTI && (
                <ProgressBars
                  numPlayers={numPlayers}
                  progress={this.state.progress}
                  playerIndex={playerIndex}
                />
              )}
              <br />

              {/* Cat image */}
              <div className="image-container">
                <img className="KeyboardFrame" src={Keyboard} alt="" />
                <img className={this.state.laserStyleLine} src={LaserLine} alt = ""/>
                <img className={this.state.laserStylePointer} src={this.state.laserFramePointer} alt = ""/>
                <img className={this.state.laserStyleDot} src={this.state.laserFrameDot} alt = ""/>
                <img className="FlameFrame" src={this.state.currentFlameFrame} alt = ""/>
                <img className={this.state.catStyleLeftArm} src={this.state.catFrameLeftArm} alt="" />
                <img className={this.state.catStyleRightArm} src={this.state.catFrameRightArm} alt =""/>
                <img className={this.state.catStyleBody} src={this.state.catFrameBody} alt = ""/>
              </div>

              {/* Prompt */}
              <Prompt
                promptedWords={this.state.promptedWords}
                typedWords={this.state.typedWords}
                currWord={this.state.currWord}
              />

              {/* Input field */}
              <br />
                <Input
                  key={this.state.promptedWords[0]}
                  correctWords={this.state.promptedWords}
                  words={this.state.typedWords}
                  onWordTyped={this.handleWordTyped}
                  onChange={this.handleChange}
                  popPrevWord={this.popPrevWord}
                  active={this.state.inputActive}
                  handleKeyDown={this.handleKeyDown}
                />
              <br />

              {/* More settings */}
              {this.state.mode !== Mode.MULTI && (
                <>
                  <Options
                    pigLatin={this.state.pigLatin}
                    onPigLatinToggle={this.handlePigLatinToggle}
                    autoCorrect={this.state.autoCorrect}
                    onAutoCorrectToggle={this.handleAutoCorrectToggle}
                    onRestart={this.initialize}
                  />
                  <br />
                  <TopicPicker onClick={this.handleSetTopics} />
                </>
              )}
              {this.state.mode === Mode.MULTI && (
                <FastestWordsDisplay
                  playerIndex={playerIndex}
                  fastestWords={fastestWords}
                />
              )}

            </div>
          </div>
        </div>

        {/* Conditionally rendered components */}
        <OpeningDialog
          show={this.state.mode === Mode.WELCOME}
          setMode={this.setMode}
          toggleFindingOpponents={this.toggleFindingOpponents}
        />
        <LoadingDialog
          show={this.state.mode === Mode.WAITING}
          numPlayers={this.state.numPlayers}
        />
        <Leaderboard
          show={this.state.showLeaderboard}
          onHide={this.toggleLeaderBoard}
        />
        <HighScorePrompt
          key={this.state.showUsernameEntry}
          wpm={this.state.wpm}
          show={this.state.showUsernameEntry}
          needVerify={this.state.needVerify}
          onHide={this.hideUsernameEntry}
          onSubmit={this.handleUsernameSubmission}
        />
      </>
    );
  }
}

export default App;